import React, {useState} from 'react'
import loadable from '@loadable/component'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import https from 'https'
import useForm from '../../Hooks/useForm'
import {requestPassword} from '../../../utils/WordPress'

const EmailSubmit = ({setDone, handleErrors}) => {
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState([])

  const sendEmailRequest = async () => {
    setLoading(true)

    try {
      const res = await requestPassword(values.email)
      console.log('res: ', res)
      setDone(
        'Password reset request received. Please check your inbox for further instructions.',
      )
    } catch (responseErr) {
      try {
        console.log(responseErr)
        if (responseErr.message) {
          setApiError(responseErr.message)
        } else {
          setApiError(
            'Your request could not be processed at this time. Please try again later',
          )
        }
      } catch (e) {
        setApiError(
          'Your request could not be processed at this time. Please try again later',
        )
      }
    }

    setLoading(false)
  }

  const {values, handleChange, handleSubmit, errors} = useForm(
    sendEmailRequest,
    validate,
  )

  return (
    <Form onSubmit={handleSubmit} loading={loading} error={!!errors}>
      {apiError.length !== 0 ? handleErrors(apiError) : null}
      <Message
        header="Special Notice"
        content="All accounts created before 30 October 2019 are no longer live. Please register a new account if you are affected. All your orders are still on record for warranty purposes"
      />
      <p>
        Enter your email address below and we will help you reset your password
      </p>
      <Form.Field>
        <Input
          label="Email"
          id="email"
          fluid
          name="email"
          type="email"
          onChange={handleChange}
          value={values.email || ''}
          className={errors.email && 'error'}
        />
        {errors.email && (
          <div className="message error attached">{errors.email}</div>
        )}
      </Form.Field>
      <div className="form-footer">
        <Button type="submit" className="button-primary">
          Submit
        </Button>
      </div>
    </Form>
  )
}

export default EmailSubmit

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Email address is required'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid'
  }
  return errors
}
