import React, {useState, useEffect} from 'react'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'

import styled from 'styled-components'
import Layout from '../components/Layout'
import PageHeader from '../components/Layout/PageHeader'
import SEO from '../components/SEO'
import {getQueryVariable} from '../utils'
import EllipsisLoader from '../components/EllipsisLoader'
import {Inner} from '../components/styles'
import EmailSubmit from '../components/PasswordReset/EmailSubmit'
import PasswordSubmit from '../components/PasswordReset/PasswordSubmit'
import {serverErrors} from '../services/serverErrors'

const FormStyles = styled.div`
  margin-bottom: 60px !important;
  .ui.label.label {
    min-width: 160px;
    text-transform: uppercase;
  }
  .form-footer {
    text-align: center;
  }
  .ui.fluid.error {
    input {
      border-color: #d4909e;
      border-bottom-right-radius: 0;
    }
    .label {
      background-color: #d4909e;
      border-bottom-left-radius: 0;
    }
  }
  @media (max-width: 500px) {
    .ui.fluid.input {
      display: grid;
      grid-template-columns: 1fr;
    }
    .ui.label.label {
      min-width: auto;
      border-top-right-radius: 5px !important;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .ui.form .field .ui.input input {
      width: 100% !important;
      border: 1px solid rgba(34, 36, 38, 0.15) !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
`

const PasswordReset = ({location}) => {
  const [done, setDone] = useState('')
  const [tk, setTk] = useState('loading')

  useEffect(() => {
    getQueryVariable('tok', setTk)
  })

  const content = () => {
    if (tk == 'loading') {
      return <EllipsisLoader height="450px" />
    } else if (tk.length > 1) {
      return (
        <PasswordSubmit tk={tk} setDone={setDone} handleErrors={handleErrors} />
      )
    } else {
      return <EmailSubmit setDone={setDone} handleErrors={handleErrors} />
    }
  }

  const handleErrors = errors => {
    if (!Array.isArray(errors)) {
      if (errors.length > 0) {
        return <Message error header="Sorry" content={errors} />
      } else {
        return (
          <Message
            error
            header="Sorry"
            content="Could not reset password at this time"
          />
        )
      }
    }
    return errors.map(e => {
      if (e.status in serverErrors) {
        return (
          <Message
            error
            header={serverErrors[e.status].title}
            content={serverErrors[e.status].detail}
            key={e.status}
          />
        )
      }
      return (
        <Message
          error
          header="Sorry"
          content="Could not reset password at this time"
        />
      )
    })
  }

  return (
    <Layout location={location} flush>
      <SEO location={location} title="Login" />
      <PageHeader location={location} hideCrumbs>
        Password Reset
      </PageHeader>
      <FormStyles>
        <Inner>
          {done ? (
            <Message positive header="Success" content={done} />
          ) : (
            content()
          )}
        </Inner>
      </FormStyles>
    </Layout>
  )
}

export default PasswordReset
