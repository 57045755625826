import React, {useState, useEffect} from 'react'
import loadable from '@loadable/component'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import https from 'https'
import useForm from '../../Hooks/useForm'
import {submitPassword} from '../../../utils/WordPress'

const PasswordSubmit = ({tk, setDone, handleErrors}) => {
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState([])

  const sendResetRequest = async () => {
    setLoading(true)
    try {
      const res = await submitPassword(values.password, tk)
      console.log('res: ', res)
      if (res.message) {
        setDone(res.message)
      } else {
        setDone(
          'Password reset request received. Please check your inbox for further instructions.',
        )
      }
    } catch (responseErr) {
      try {
        console.log(responseErr)
        if (responseErr.message) {
          setApiError(responseErr.message)
        } else {
          setApiError(
            'Your request could not be processed at this time. Please try again later',
          )
        }
      } catch (e) {
        setApiError(
          'Your request could not be processed at this time. Please try again later',
        )
      }
    }

    setLoading(false)
  }

  const {values, handleChange, handleSubmit, errors} = useForm(
    sendResetRequest,
    validate,
  )

  return (
    <Form onSubmit={handleSubmit} loading={loading} error={!!errors}>
      {apiError.length !== 0 ? handleErrors(apiError) : null}
      <p>Please enter a new password for your account.</p>
      <Form.Field>
        <Input
          label="Password"
          id="password"
          fluid
          name="password"
          type="password"
          onChange={handleChange}
          value={values.password || ''}
          className={(errors.password || errors.rePassword) && 'error'}
        />
        {errors.password && (
          <div className="message error attached">{errors.password}</div>
        )}
      </Form.Field>
      <Form.Field>
        <Input
          label="Re-enter Password"
          id="rePassword"
          fluid
          name="rePassword"
          type="password"
          onChange={handleChange}
          value={values.rePassword || ''}
          className={errors.rePassword && 'error'}
        />
        {errors.rePassword && (
          <div className="message error attached">{errors.rePassword}</div>
        )}
      </Form.Field>
      <div className="form-footer">
        <Button type="submit" className="button-primary">
          Submit
        </Button>
      </div>
    </Form>
  )
}

export default PasswordSubmit

const validate = values => {
  const errors = {}
  if (!values.password) {
    errors.password = 'Password is required'
  } else if (values.password !== values.rePassword) {
    errors.rePassword = 'Passwords do not match'
  } else if (values.password.length < 6) {
    errors.password = 'Password should be at least 6 characters'
  }
  return errors
}
